<template>
  <div class="container-list">
    <div class="breadcrumb-container">
      <ul class="breadcrumb">
        <li @click="goBack">
          <a href="#"><font-awesome-icon icon="building" class="fa-xl" style="min-width: 40px" /></a>
        </li>
        <li>
          <a href="#">{{ company }}</a>
        </li>
      </ul>
      <span class="selection">
        <material-button
          v-if="permissions.canUse('COMPANY_CAMPAIGN_VIEW')"
          :small="true"
          :text="correnti+' Correnti'"
          type="info"
          :class="{disabled: campaignsType != 'current'}"
          @click="getCampaignsType('current')"
        />
        <material-button
          v-if="permissions.canUse('COMPANY_CAMPAIGN_EXPIRED_VIEW')"
          :small="true"
          :text="scadute+' Scadute'"
          type="info"
          :class="{disabled:campaignsType != 'expired'}"
          @click="getCampaignsType('expired')"
        />
        <material-button
          v-if="permissions.canUse('COMPANY_CAMPAIGN_ARCHIVE_VIEW')"
          :small="true"
          :text="archiviate+' Archiviate'"
          type="info"
          :class="{disabled:campaignsType != 'archived'}"
          @click="getCampaignsType('archived')"
        />
      </span>
    </div>
    <div class="page-container">
      <div class="topHeader">
        <searchBar :search="search" @update:search="debounceSearch($event)" />
      </div>

      <div class="table campaignsList" :class="pages > 1 ? 'table-pagination' : ''">
        <table cellspacing="0">
          <thead>
            <th class="th100" />
            <th class="orderable th50 text-center" @click="changeOrder('Cid')">
              <div class="text-center">
                <span>Cid</span>
                <font-awesome-icon v-if="filters.order.field == 'Cid'" :icon="filters.order.asc ? 'arrow-down-a-z' : 'arrow-down-z-a'" />
              </div>
            </th>
            <th
              class="orderable"
              @click="changeOrder('Campagna')"
            >
              <div class="text-left">
                <span>Campagna</span>
                <font-awesome-icon v-if="filters.order.field == 'Campagna'" :icon="filters.order.asc ? 'arrow-down-a-z' : 'arrow-down-z-a'" />
              </div>
            </th>
            <th class="orderable" @click="changeOrder('Creata')">
              <div class="text-left">
                <span>Creazione → Scadenza</span>
                <font-awesome-icon v-if="filters.order.field == 'Creata'" :icon="filters.order.asc ? 'arrow-down-a-z' : 'arrow-down-z-a'" />
              </div>
            </th>
            <th class="orderable" @click="changeOrder('Città')">
              <div class="text-left">
                <span>Città</span>
                <font-awesome-icon v-if="filters.order.field == 'Città'" :icon="filters.order.asc ? 'arrow-down-a-z' : 'arrow-down-z-a'" />
              </div>
            </th>
            <th class="orderable th100">
              <div @click="changeOrder('Candidati Totali')">
                <span>Candidati totali</span>
                <font-awesome-icon v-if="filters.order.field == 'Candidati Totali'" :icon="filters.order.asc ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
              </div>
            </th>
            <th>
              <div class="order-container">
                <div class="order-column">
                  <div class="order-item" @click="changeOrder('Candidati Suggeriti')">
                    <div class="color-box suggeriti" />
                    <span>Suggeriti</span>
                    <font-awesome-icon :class="{ 'order-visible': filters.order.field == 'Candidati Suggeriti' }" :icon="filters.order.asc ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
                  </div>
                  <div class="order-item" @click="changeOrder('Candidati Spontanei')">
                    <div class="color-box spontanei" />
                    <span>Spontanei</span>
                    <font-awesome-icon :class="{ 'order-visible': filters.order.field == 'Candidati Spontanei' }" :icon="filters.order.asc ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
                  </div>
                  <div class="order-item" @click="changeOrder('Candidati da anonimizzare')">
                    <div class="color-box daanon" />
                    <span>Da anonimizzare</span>
                    <font-awesome-icon :class="{ 'order-visible': filters.order.field == 'Candidati da anonimizzare' }" :icon="filters.order.asc ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
                  </div>
                  <div class="order-item" @click="changeOrder('Candidati mai visualizzati')">
                    <div class="color-box maivisualizzati" />
                    <span>Mai visualizzati</span>
                    <font-awesome-icon :class="{ 'order-visible': filters.order.field == 'Candidati mai visualizzati' }" :icon="filters.order.asc ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
                  </div>
                </div>
                <div class="order-column">
                  <div class="order-item" @click="changeOrder('Candidati Anonimizzati')">
                    <div class="color-box anonimizzati" />
                    <span>Anonimizzati</span>
                    <font-awesome-icon :class="{ 'order-visible': filters.order.field == 'Candidati Anonimizzati' }" :icon="filters.order.asc ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
                  </div>
                  <div class="order-item" @click="changeOrder('Candidati Sbloccati')">
                    <div class="color-box sbloccati" />
                    <span>Sbloccati</span>
                    <font-awesome-icon :class="{ 'order-visible': filters.order.field == 'Candidati Sbloccati' }" :icon="filters.order.asc ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
                  </div>
                  <div class="order-item" @click="changeOrder('Candidati Eliminati')">
                    <div class="color-box eliminati" />
                    <span>Eliminati</span>
                    <font-awesome-icon :class="{ 'order-visible': filters.order.field == 'Candidati Eliminati' }" :icon="filters.order.asc ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
                  </div>
                </div>
              </div>
            </th>
            <th class="th50" />
          </thead>
          <tbody class="table-body">
            <slot v-for="(campaign, index) in getVisiblePageElements()" :id="campaign.cid + 'panel'" :key="index">
              <tr class="cursor-pointer" @click="toggleMasterDetail(campaign.cid)">
                <td class="cover-logo">
                  <img class="cover" :src="campaign.cover">
                  <div v-show="campaign['visibilità']" class="lock-in">
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="#fff"
                        d="M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z"
                      />
                    </svg>
                    <label>Visibile su CVing</label>
                  </div>
                </td>
                <td>
                  <span class="badge coid tet-center">{{ campaign.cid }}</span>
                </td>
                <td>
                  <div class="campaign-title">
                    {{ getCampaignTitle(campaign.title) }}
                  </div>
                </td>

                <td>
                  <div v-text="formatDate(campaign.data_creazione) + ' → ' + formatDate(campaign.expire)" />
                  <div style="font-size:12px; margin-top:5px" v-text="daysRemaining(campaign.expire)" />
                </td>
                <td>
                  <div v-if="campaign.sede">
                    <div v-for="(city, j) in getCitiesFromString(campaign.sede, 4)" :key="j">
                      <span>{{ city }}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="text-center">
                    <span class="badge">
                      {{ campaign.totalnumberofcandidates }}
                    </span>
                  </div>
                </td>
                <td>
                  <div class="order-container">
                    <div class="order-column">
                      <div class="order-item">
                        <div class="color-box suggeriti" />
                        <span class="item-box">{{ campaign.numberofsuggested }}</span>
                      </div>
                      <div class="order-item">
                        <div class="color-box spontanei" />
                        <span class="item-box">{{ campaign.candidates_spontaneous }}</span>
                      </div>
                      <div class="order-item">
                        <div class="color-box daanon" />
                        <span class="item-box">{{ campaign.candidates_to_be_anonymized }}</span>
                      </div>
                      <div class="order-item">
                        <div class="color-box maivisualizzati" />
                        <span class="item-box">{{ campaign.unseen_candidates_count }}</span>
                      </div>
                    </div>
                    <div class="order-column">
                      <div class="order-item">
                        <div class="color-box anonimizzati" />
                        <span class="item-box">{{ campaign.candidates_anonymized }}</span>
                      </div>
                      <div class="order-item">
                        <div class="color-box sbloccati" />
                        <span class="item-box">{{ campaign.candidates_unlocked }}</span>
                      </div>
                      <div class="order-item">
                        <div class="color-box eliminati" />
                        <span class="item-box">{{ campaign.totalnumberofdeletedcandidates }}</span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <font-awesome-icon :icon="collapsiblePanelOpened == campaign.cid ? 'angle-down' : 'angle-right'" class="fa-md" />
                </td>
              </tr>
              <tr v-if="collapsiblePanelOpened == campaign.cid" class="collapsible-content" style="height: 50vh">
                <td colspan="9" class="content-inner" style="padding: 0 !important">
                  <campaignManagement
                    v-if="campaignSelected"
                    :selected="campaignSelected"
                    :autogrill="isAutogrill"
                    :umana="isUmana"
                    @update:selected="updateCampaign($event)"
                    @closePanel="closePanel($event)"
                  />
                </td>
              </tr>
            </slot>
          </tbody>
        </table>
        <div v-if="campaigns.length === 0 && loaded" class="payment-alert">
          <span class="payment-badge">
            <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M21 11.11V5C21 3.9 20.11 3 19 3H14.82C14.4 1.84 13.3 1 12 1S9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.11 3.9 21 5 21H11.11C12.37 22.24 14.09 23 16 23C19.87 23 23 19.87 23 16C23 14.09 22.24 12.37 21 11.11M12 3C12.55 3 13 3.45 13 4S12.55 5 12 5 11 4.55 11 4 11.45 3 12 3M5 19V5H7V7H17V5H19V9.68C18.09 9.25 17.08 9 16 9H7V11H11.1C10.5 11.57 10.04 12.25 9.68 13H7V15H9.08C9.03 15.33 9 15.66 9 16C9 17.08 9.25 18.09 9.68 19H5M16 21C13.24 21 11 18.76 11 16S13.24 11 16 11 21 13.24 21 16 18.76 21 16 21M16.5 16.25L19.36 17.94L18.61 19.16L15 17V12H16.5V16.25Z"
              />
            </svg>
          </span>
          <span>Nessuna campagna trovata!</span>
        </div>
      </div>

      <div v-if="pages > 1" class="pagination">
        <v-pagination v-model="page" :pages="pages" :range-size="1" active-color="#DCEDFF" />
      </div>
    </div>
  </div>
</template>

<script>
import searchBar from '../../searchBarList.vue'
import { useRoute } from 'vue-router'
import { inject, onMounted, computed, reactive, toRefs, onBeforeMount, ref } from 'vue'
import moment from 'moment'
import router from '../../../router'
import campaignManagement from './CampaignManagement.vue'
import { sbapibackoffice } from '@/api'
import VPagination from '@hennge/vue3-pagination'
import { spinnerStore } from '@/stores/spinner'
import '@hennge/vue3-pagination/dist/vue3-pagination.css'
import { navbarStore } from '@/stores/navbar'
import { permissionsStore } from '@/stores/permissions'
// import Tooltip from '../../Tooltip.vue'

export default {
  components: {
    searchBar,
    campaignManagement,
    VPagination
    // Tooltip
  },
  setup() {
    const toast = inject('$toast')
    const spinner = spinnerStore()
    const navbar = navbarStore()
    const permissions = permissionsStore()
    const state = reactive({
      loaded: false,
      search: '',
      filter: 'datadsc',
      coid: null,
      company: null,
      campaigns: [],
      selectAll: [],
      checked: null,
      newFormModal: false,
      windowWidth: window.innerWidth,
      page: 1,
      itemsPerPage: 15,
      campaignSelected: {},
      isAutogrill: false,
      isUmana: false,
      filtred: [],
      scrollDiv: null,
      correnti: 0,
      archiviate:0,
      scadute:0,

      collapsiblePanelOpened: null,
      filters: {
        order: {
          field: 'Campagna',
          asc: true
        }
      },
      campaignsType: 'current'
    })
    onBeforeMount(() => {
      const route = useRoute()
      state.coid = route.query.coid
      state.company = route.query.company
      state.archiviate = route.query.archiviate ? route.query.archiviate : 0
      state.correnti = route.query.correnti ? route.query.correnti : 0
      state.scadute = route.query.scadute ? route.query.scadute : 0
      
      getCampaignsType('current')
    })
    onMounted(() => {
      navbar.title = 'Lista campagne di ' + state.company
      window.addEventListener('resize', () => {
        state.windowWidth = window.innerWidth
      })
      state.scrollDiv = document.getElementById('containerDiv')
    })

    // used to manage the search input debounce time
    const typingTimer = ref(null)

    const debounceSearch = (event) => {
      clearTimeout(typingTimer.value)
      typingTimer.value = setTimeout(() => {
        state.search = event
      }, 1000)
    }

    const getCampaignTitle = (title) => {
      let spaceCount = 0
      let result = ''

      for (let i = 0; i < title.length; i++) {
        if (title[i] === ' ') {
          spaceCount++
          if (spaceCount === 3) {
            result += '\n'
            continue
          }
        }
        result += title[i]
      }

      /* if (result.length >= 25) {
        result = result.slice(0, 22) + '...'
      } */

      return result
    }

    const getVisiblePageElements = () => {
      let campaignsFiltred = []

      const start = (state.page - 1) * state.itemsPerPage
      const end = start + state.itemsPerPage

      campaignsFiltred = state.campaigns.filter((campaign) => {
        if (campaign && campaign.title) {
          let name = campaign.title.toLowerCase()
          let cid = campaign.cid.toString()
          let city = campaign.sede.toLowerCase()
          let search = state.search.toLowerCase()
          if (
            name.includes(search) ||
            cid.includes(search) ||
            city.includes(search)
          ) {
            return true
          }
          return false
        }
      })

      if (state.filters.order.field === 'Campagna') {
        if (state.filters.order.asc) {
          campaignsFiltred = campaignsFiltred.sort(function (a, b) {
            return a.title.toUpperCase() < b.title.toUpperCase() ? -1 : a.title.toUpperCase() > b.title.toUpperCase() ? 1 : 0
          })
        } else {
          campaignsFiltred = campaignsFiltred.sort(function (a, b) {
            return b.title.toUpperCase() < a.title.toUpperCase() ? -1 : b.title.toUpperCase() > a.title.toUpperCase() ? 1 : 0
          })
        }
      }

      if (state.filters.order.field === 'Cid') {
        if (state.filters.order.asc) {
          campaignsFiltred = campaignsFiltred.sort(function (a, b) {
            return a.cid < b.cid ? -1 : a.cid > b.cid ? 1 : 0
          })
        } else {
          campaignsFiltred = campaignsFiltred.sort(function (a, b) {
            return b.cid < a.cid ? -1 : b.cid > a.cid ? 1 : 0
          })
        }
      }
      if (state.filters.order.field === 'Candidati Totali') {
        campaignsFiltred = campaignsFiltred.sort(function (a, b) {
          return state.filters.order.asc ? Number(a.totalnumberofcandidates) - Number(b.totalnumberofcandidates) : Number(b.totalnumberofcandidates) - Number(a.totalnumberofcandidates)
        })
      }
      if (state.filters.order.field === 'Candidati Eliminati') {
        campaignsFiltred = campaignsFiltred.sort(function (a, b) {
          return state.filters.order.asc ? Number(a.totalnumberofdeletedcandidates) - Number(b.totalnumberofdeletedcandidates) : Number(b.totalnumberofdeletedcandidates) - Number(a.totalnumberofdeletedcandidates)
        })
      }

      if (state.filters.order.field === 'Candidati Suggeriti') {
        campaignsFiltred = campaignsFiltred.sort(function (a, b) {
          return state.filters.order.asc? a.numberofsuggested - b.numberofsuggested : b.numberofsuggested - a.numberofsuggested 
        })
      }
      if (state.filters.order.field === 'Candidati Spontanei') {
        campaignsFiltred = campaignsFiltred.sort(function (a, b) {
          return state.filters.order.asc? a.candidates_spontaneous - b.candidates_spontaneous : b.candidates_spontaneous - a.candidates_spontaneous
        })
      }
      if (state.filters.order.field === 'Candidati da anonimizzare') {
        campaignsFiltred = campaignsFiltred.sort(function (a, b) {
          return state.filters.order.asc? a.candidates_to_be_anonymized - b.candidates_to_be_anonymized : b.candidates_to_be_anonymized - a.candidates_to_be_anonymized
        })
      }

      if (state.filters.order.field === 'Candidati mai visualizzati') {
        campaignsFiltred = campaignsFiltred.sort(function (a, b) {
          return state.filters.order.asc ? a.unseen_candidates_count - b.unseen_candidates_count : b.unseen_candidates_count - a.unseen_candidates_count
        })
      }

      if (state.filters.order.field === 'Candidati Anonimizzati') {
        campaignsFiltred = campaignsFiltred.sort(function (a, b) {
          return state.filters.order.asc ? a.candidates_anonymized - b.candidates_anonymized : b.candidates_anonymized - a.candidates_anonymized
        })
      }

      if (state.filters.order.field === 'Candidati Sbloccati') {
        campaignsFiltred = campaignsFiltred.sort(function (a, b) {
          return state.filters.order.asc ? a.candidates_unlocked - b.candidates_unlocked : b.candidates_unlocked - a.candidates_unlocked
        })
      }

      if (state.filters.order.field === 'Creata') {
        if (state.filters.order.asc) {
          campaignsFiltred = campaignsFiltred.sort((a, b) => {
            if (moment(a.data_creazione) > moment(b.data_creazione)) return 1
            if (moment(a.data_creazione) < moment(b.data_creazione)) return -1
            return 0
          })
        } else {
          campaignsFiltred = campaignsFiltred.sort((a, b) => {
            if (moment(b.data_creazione) > moment(a.data_creazione)) return 1
            if (moment(b.data_creazione) < moment(a.data_creazione)) return -1
            return 0
          })
        }
      }

      if (state.filters.order.field === 'Città') {
        if (state.filters.order.asc) {
          campaignsFiltred = campaignsFiltred.sort(function (a, b) {
            return a.sede.toUpperCase() < b.sede.toUpperCase() ? -1 : a.sede.toUpperCase() > b.sede.toUpperCase() ? 1 : 0
          })
        } else {
          campaignsFiltred = campaignsFiltred.sort(function (a, b) {
            return b.sede.toUpperCase() < a.sede.toUpperCase() ? -1 : b.sede.toUpperCase() > a.sede.toUpperCase() ? 1 : 0
          })
        }
      }

      Object.assign(state.filtred, [])
      Object.assign(state.filtred, campaignsFiltred)
      // state.filtred = campaignsFiltred
      return campaignsFiltred.slice(start, end)
    }

    const pages = computed(() => {
      return Math.ceil(state.filtred.length / state.itemsPerPage)
    })
    const select = (cid) => {
      if (state.selectAll.indexOf(cid) === -1) {
        state.selectAll.push(cid)
      } else {
        let index = state.selectAll.indexOf(cid)
        state.selectAll.splice(index, 1)
      }
    }
    const selectAllCampaigns = () => {
      if (state.selectAll.length != state.campaigns.length && state.campaigns.length / 2 > state.selectAll.length) {
        state.campaigns.forEach((campaign) => {
          if (state.selectAll.indexOf(campaign.cid) === -1) {
            state.selectAll.push(campaign.cid)
          }
        })
      } else {
        state.selectAll = []
      }
    }
    const formatDate = (date) => {
      return state.windowWidth >= 1600 ? moment(date).locale('it').format('DD MMMM YYYY') : moment(date).locale('it').format('DD/MM/YY')
    }
    const daysRemaining = (date) => {
      moment.locale('it')
      let eventdate = moment(date)
      let todaysdate = moment()
      let diff = eventdate.diff(todaysdate, 'days')

      if (diff >= 0) {
        return 'Scade tra ' + eventdate.toNow(true)
      } else {
        return 'È scaduta da ' + eventdate.fromNow(true)
      }

    }
    /**
     * Get cities from string, if max is set, return only the first max cities
     * and if the number of cities is greater than max, add a '+n' at the end
     * @param cities string with pipeline separations
     * @param max max number of cities to show
     */
    const getCitiesFromString = (cities, max) => {
      let citiesArray = cities.split('|')
      let citiesArrayLength = citiesArray.length
      if (max && citiesArrayLength > max) {
        citiesArray = citiesArray.slice(0, max)
        citiesArray.push('+' + (citiesArrayLength - max))
      }
      return citiesArray
    }
    const goBack = () => {
      console.log('push')
      router.push({ path: '/companiesList' })
    }
    const getCampaign = (cid) => {
      let newCall = state.checked != cid
      state.checked = null
      Object.assign(state.campaignSelected, {})
      if (newCall) {
        sbapibackoffice
          .get('/campaignsbycid?cid=' + cid)
          .then((campaign) => {
            if(!campaign.bakeca) {
              campaign.bakeca = false
            }
            Object.assign(state.campaignSelected, campaign.data[0])
            state.scrollDiv.scrollTop = 0
            state.checked = cid
            console.log('get campaign', cid)
          })
          .catch((err) => {
            toast.warning('Non è stato possibile recuperare i dati della campagna: ' + err)
          })
      }
    }
    const updateCampaign = (newCampaign) => {
      Object.assign(state.campaignSelected, newCampaign)
    }
    const closePanel = (ev) => {
      //state.campaignSelected = {}
      //state.checked = null
      state.collapsiblePanelOpened = null
      spinner.hide()
      if (ev.err) {
        toast.warning('Non è stato possibile aggiornare la campagna: ' + ev.error)
      } else {
        state.campaigns.forEach((campaign) => {
          if (campaign.cid === ev.cid) {
            campaign.title = ev.title
            campaign.sede = ev.city
            campaign['visibilità'] = ev.private
            campaign.extension = 0
            campaign.expire = ev.expire
          }
        })
        toast.success('Campagna aggiornata!')
      }
    }

    const toggleMasterDetail = (cid) => {
      if (state.collapsiblePanelOpened == cid) state.collapsiblePanelOpened = null
      else {
        Object.assign(state.campaignSelected, {})
        spinner.show()
        sbapibackoffice
          .get('/campaignsbycid?cid=' + cid)
          .then((campaign) => {
            Object.assign(state.campaignSelected, campaign.data[0])
            state.collapsiblePanelOpened = cid
          })
          .catch((err) => {
            toast.error('Non è stato ottenere i dettagli della campagna: ' + err)
          })
          .finally(spinner.hide)
      }
    }

    /**
		 * Set the order condition for the table
		 * @param {number} orderBy
		 */
    const changeOrder = (orderBy) => {
      if (state.filters.order.field == orderBy) {
        state.filters.order.asc = !state.filters.order.asc
      } else {
        state.filters.order.field = orderBy
        state.filters.order.asc = true
      }
    }

    const expirationFormatted = (expire) => {
      return moment(expire).format('DD/MM/YY')
    }

    const getCampaignsType = (campaignsType) => {

      state.campaignsType = campaignsType
      // current = expired false & archived = false
      // expired = expired true & archived = false
      // archived = expired false & archived = true

      spinner.show()
      state.loaded = false
      
      sbapibackoffice
        .get(`/campaigns?adSource=recruiters&coid=${state.coid}&expired=${campaignsType === 'expired'}&archived=${campaignsType === 'archived'}`)
        .then((campaigns) => {
          state.filtred = []
          state.campaigns = []

          Object.assign(state.campaigns, campaigns.data)
          if (state.coid === '454' || state.coid === '271') {
            state.isUmana = true
          }
          if (state.coid === '254' || state.coid === '250') {
            state.isAutogrill = true
          }
        })
        .catch((err) => this.toast.error('Non è stato possibile ottenere le campagne: ' + err))
        .finally(() => {
          spinner.hide()
          state.loaded = true
        })
    }

    return {
      ...toRefs(state),
      selectAllCampaigns,
      formatDate,
      daysRemaining,
      goBack,
      select,
      getCitiesFromString,
      pages,
      debounceSearch,
      getVisiblePageElements,
      getCampaign,
      updateCampaign,
      closePanel,
      toggleMasterDetail,
      changeOrder,
      expirationFormatted,
      getCampaignsType,
      permissions,
      getCampaignTitle
    }
  }
}
</script>

<style lang="scss">
.campaignsList {
  table-layout:fixed;
  th.th50 {
		width:50px;
	}
	th.th80 {
		width:80px;
	}
	th.th100 {
		width:100px;
	}
}
.limitSize {
	max-width: 20%;
}
.pagination {
	padding: 20px 0;
  display: flex;
  justify-content: flex-end;
	ul.Pagination {
		justify-content: flex-end;
		li.PaginationControl > path {
			fill: rgba(255, 255, 255, 0.233);
		}
		.Control-active {
			fill: white;
		}
		.Page {
			width: 25px;
			height: 25px;
			color: white;
		}
		button.Page-active {
			background: var(--accented) !important;
			border: 1px solid var(--accented) !important;
			color: #003e73;
			font-size: 14px;
			font-weight: 600;
		}
	}
}
</style>

<style lang="scss" scoped>
@use '../../../assets/scss/table';
@import '../../../assets/scss/lists.scss';
.table table td {
	padding: 0.5rem;
}
.expansion-panel-container {
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 5px;
	max-height: 65vh;
	overflow: hidden;
	margin: 5px 0;
}
.lock-in {
	position: absolute;
	left: 8px;
	bottom: 5px;
	border-radius: 50%;
	height: 24px;
	width: 24px;
	background: #05b1c2;
	box-shadow: 0 8px 32px 0 #05b1c210;
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
	border: 1px solid white;
	svg {
		fill: currentColor;
		height: 75%;
		left: 50%;
		position: absolute;
		stroke: currentColor;
		stroke-width: 0;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 75%;
	}
	label {
		display: block;
		opacity: 0;
		background: #004278e9;
		box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
		backdrop-filter: blur(6px);
		-webkit-backdrop-filter: blur(4px);
		border-radius: 10px;
		border: 1px solid rgba(255, 255, 255, 0.3);
		color: white;
		position: absolute;
		width: 130px;
		left: -20px;
		text-align: center;
		padding: 2px 0;
		border-radius: 2px;
		top: 0px;
		-webkit-transition: all 0.6s ease-in-out;
		-moz-transition: all 0.6s ease-in-out;
		-ms-transition: all 0.6s ease-in-out;
		-o-transition: all 0.6s ease-in-out;
		transition: all 0.6s ease-in-out;
	}
}
.lock-in:hover {
	label {
		opacity: 1;
		display: block;
		top: -28px;
		-webkit-transition: all 0.4s ease-in-out;
		-moz-transition: all 0.4s ease-in-out;
		-ms-transition: all 0.4s ease-in-out;
		-o-transition: all 0.4s ease-in-out;
		transition: all 0.4s ease-in-out;
	}
}
.scroll {
	height: calc(100% - 65px);
	overflow: hidden;
	overflow-y: auto;
}
div.breadcrumb-container {
	display: flex;
  justify-content: space-between;
	padding: 35px 50px 0px 50px;
	
	.breadcrumb {
		padding: 0;
		margin-bottom: 0;
		height: 35px;
		list-style: none;
		display: inline-block;
	}
	.breadcrumb .icon {
		font-size: 14px;
	}
	.breadcrumb li {
		float: left;
	}
	.breadcrumb li a {
		color: #fff;
		display: block;
		background: #4ba8c535;
		text-decoration: none;
		position: relative;
		height: 35px;
		line-height: 35px;
		padding: 0 10px 0 5px;
		text-align: center;
		margin-right: 23px;
	}
	.breadcrumb li:not(:last-of-type) a {
		border-color: #ffffff;
	}
	.breadcrumb li:not(:last-of-type):nth-child(even) a {
		background-color: #2980b9;
	}
	.breadcrumb li:not(:last-of-type):nth-child(even) a:before {
		border-color: #2980b9;
		border-left-color: transparent;
	}
	.breadcrumb li:not(:last-of-type):nth-child(even) a:after {
		border-left-color: #2980b9;
	}
	.breadcrumb li:first-child a {
		padding-left: 15px;
		-moz-border-radius: 4px 0 0 4px;
		-webkit-border-radius: 4px;
		border-radius: 4px 0 0 4px;
	}
	.breadcrumb li:first-child a:before {
		border: none;
	}
	.breadcrumb li:last-child a {
		padding-right: 15px;
		-moz-border-radius: 0 4px 4px 0;
		-webkit-border-radius: 0;
		border-radius: 0 4px 4px 0;
	}
	.breadcrumb li:last-child a:after {
		border: none;
	}
	.breadcrumb li a:before,
	.breadcrumb li a:after {
		content: '';
		position: absolute;
		top: 0;
		border: 0 solid #4ba8c535;
		border-width: 17.5px 10px;
		width: 0;
		height: 0;
	}
	.breadcrumb li a:before {
		left: -20px;
		border-left-color: transparent;
	}
	.breadcrumb li:not(:last-of-type) a:after {
		left: 100%;
		border-color: transparent;
		border-left-color: #4ba8c535;
	}
	.breadcrumb li:not(:last-of-type) a:hover {
		background-color: #0db1c435;
	}
	.breadcrumb li:not(:last-of-type) a:hover:before {
		border-color: #0db1c435;
		border-left-color: transparent;
	}
	.breadcrumb li:not(:last-of-type) a:hover:after {
		border-left-color: #0db1c435;
	}
	.breadcrumb li a:active {
		background-color: #16a085;
	}
	.breadcrumb li a:active:before {
		border-color: #16a085;
		border-left-color: transparent;
	}
	.breadcrumb li a:active:after {
		border-left-color: #16a085;
	}
}
.coid {
	font-size: 13px;
}
.panels-container {
	overflow: hidden;
	flex-direction: column;
	display: flex;
}
.cid {
	padding: 0 !important;
	flex: 0 !important;
	min-width: 100px;
	justify-content: center;
}

.cover-logo {
	max-width: 100px !important;

	position: relative;
	min-width: 100px !important;
	padding: 5px 0;
	margin-right: 20px;
	img {
		width: 100%;
		height: auto;
		display: block;
		max-width: 95px;
	}
	input {
		position: absolute;
		right: 5px;
		bottom: 5px;
	}
}
.container-list {
	position: relative;
	height: calc(100% - 80px);
	display: flex;
	flex-direction: column;
	height: 100%;
}
div.page-container {
	width: calc(100% - 100px);
	font-family: 'Lato', sans-serif;
	display: flex;
	flex-direction: column;
	box-shadow: rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.12);
	margin: 12px 50px 30px 50px;
	font-size: 14px;
	overflow-y: auto;
	max-height: 80vh;

	.header-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex: 1;
		&.m-header {
			padding: 25px 12px 25px 10px;
			font-weight: 600;
			color: white;
			.arrow {
				flex: 0;
				min-width: 50px;
			}
			div:not(.header-logo) {
				display: flex;
				flex: 1;
				padding: 0 15px;
			}
		}
	}
}
// expansion panel section
.header-list,
.wrap-collabsible.selected {
	// order: -1;
	display: flex;
	flex-direction: column;
	opacity: 1;
}
.wrap-collabsible.selected {
	// margin-bottom: 25px;
	// margin-top: 25px;
	.lbl-toggle {
		background: radial-gradient(circle at 50% 100%, rgb(23 23 25 / 50%), rgb(8 59 100 / 61%));
		//background:radial-gradient(circle at 50% 100%, #0089db9c, #0770d063);
	}
}
.wrap-collabsible.unselected {
	opacity: 0.65;
}

//Expansions panels
.header-list {
	border: 1px solid rgba(255, 255, 255, 0.15);
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

input[type='checkbox'] {
	display: none;
}
.header .lbl-toggle {
	max-height: 65px;
	height: 65px;
	border: 0;
	line-height: 65px;
}

.lbl-toggle {
	display: block;
	color: white;
	background: transparent;
	position: relative;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	cursor: pointer;
	transition: all 0.25s ease-out;
	.panel-header-container {
		//flex-wrap: wrap;
		display: flex;
		align-items: center;
		justify-items: center;
		.flex-1 {
			display: flex;
			flex: 1;
			justify-content: left;
			padding-left: 5px;
			max-height: 65px;
			span {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				text-align: center;
			}
		}
		.flex-0 {
			max-height: 65px;
			display: flex;
			flex: 0;
			justify-content: center;
			min-width: 50px;
			position: relative;
			span {
				text-align: center;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		.flex-0.select {
			margin-right: 20px;
			max-width: 150px !important;
			min-width: 150px !important;
		}
	}
}

.collapsible-content {
	max-height: 0px;
	overflow: hidden;
	transition: max-height 0.25s ease-in-out;
}

.toggle:checked + .lbl-toggle + .collapsible-content {
	max-height: 100vh;
}

.collapsible-content .content-inner {
	border-bottom: 1px solid #00dbc350;
	border-top: 1px solid #00dbc350;
	padding: 0.5rem 1rem;
}
.expansion-panel.selected .arrow svg {
	transform: rotate(90deg);
	transition: all 0.2s ease-in-out;
}
.arrow {
	flex: 0 !important;
	min-width: 50px;
	svg {
		transform: rotate(0deg);
		transition: all 0.2s ease-out;
	}
}
@media only screen and (min-width: 1026px) {
	.flex-0:not(.arrow) {
		flex: 1 !important;
		justify-content: center;
	}
}
@media only screen and (max-width: 720px) {
	.lbl-toggle {
		padding: 0rem 1rem;
	}
	.selected {
		margin: 0 !important;
	}
	div.expansion-panel.header.wrap-collabsible {
		display: none;
	}
	.panel-header-container {
		position: relative;
		flex-direction: column;
		div {
			display: block !important;
			width: 100%;
			max-width: 100% !important;
			position: relative;
			text-align: right !important;
			line-height: 38px;
			min-height: 38px;
			padding: 0 !important;
			word-spacing: 1px !important;
			width: 100% svg {
				margin: 7px;
			}
		}
		div:nth-of-type(3) div:nth-of-type(1):before {
			content: '';
		}
		div:nth-of-type(3) div:nth-of-type(2):before {
			content: 'Scade tra';
		}
		div:nth-of-type(4) div:last-of-type {
			line-height: 0;
			min-height: 5px;
			font-size: 12px;
		}
		div:before {
			text-align: left;
			position: absolute;
			display: block;
			width: 50%;
			line-height: 38px;
			min-height: 38px;
		}
		div:nth-of-type(1):before {
			content: 'Nome Campagna';
		}
		div:nth-of-type(2):before {
			content: 'Cid';
		}
		div:nth-of-type(3):before {
			content: 'Data di creazione';
		}
	}
}
@media (min-width: 601px) and (max-width: 1026px) {
	.inside {
		padding: 18px 0;
	}
}
@media (min-width: 601px) and (max-width: 1200px) {
	.lineb {
		line-height: 1.5 !important;
	}
}
// @media only screen and (min-width: 721px) {
// 	.panel-header-container {
// 		div:nth-child(1) {
// 			position: relative;
// 			min-width: 50px;
// 		}
// 		div:nth-child(2) {
// 			width: 30%;
// 			white-space: nowrap;
// 			overflow: hidden;
// 			text-overflow: ellipsis;
// 		}
// 		div:nth-child(3) {
// 			text-align: center;
// 			width: 20%;
// 		}
// 		div:nth-child(4) {
// 			width: 20%;
// 			span {
// 				display: block;
// 			}
// 		}
// 		div:nth-child(5) {
// 			width: 15%;
// 		}
// 		div:nth-child(6) {
// 			text-align: center;
// 			width: 10%;
// 		}
// 		//arrow
// 		div:nth-child(7) {
// 			text-align: center;
// 			width: 50px;
// 		}
// 	}
// }
.table {
	&.table-pagination {
		height: 100%;
	}
}

.payment-alert {
	border: 1px solid #ffffff20;
	background: #a4c84d15;
	display: flex;
	align-items: center;
	padding: 0.5rem 0.5rem;
	margin: 20px 30px;
	.payment-badge {
		background: #a4c84d55;
		width: 45px;
		height: 45px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 0.5rem;
	}
}

.payment-alert {
	border: 1px solid #ffffff20;
	background: #a4c84d15;
	display: flex;
	align-items: center;
	padding: 0.5rem 0.5rem;
	margin: 20px 30px;
	.payment-badge {
		background: #a4c84d55;
		width: 45px;
		height: 45px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 0.5rem;
	}
}

.order-container {
  gap:10px!important;
  display: flex!important;
  justify-content:  flex-start!important;
  align-items: flex-start!important;
}

.order-column {
  align-items: flex-start!important;
  display: flex;
  flex-direction: column;
  &:nth-child(1) {
width:120px;
  }
   &:nth-child(2) {
width:100px;

  }
}

.order-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 2.5px; /* Adds space between the rows */
  width:100%;
}

.color-box {
  width: 8px;
  height: 8px;
  min-width: 8px;
  min-height: 8px;
  max-width: 8px;
  max-height: 8px;
  border-radius: 1px;
  margin-right: 5px;
}
.color-box + span {
  font-size: 12px;
  width: 100%;
  text-align: left
}
.color-box + span + svg{
 opacity: 0;
 path {
  fill:#A9DC62;
 }
}
svg.order-visible {
  opacity: 1!important;
}

.color-box.suggeriti {
  background-color: #A9DC62; /* Green */
}

.color-box.spontanei {
  background-color: #61AFFF; /* Blue */
}

.color-box.daanon {
  background-color: #FFB562; /* Orange */
}

.color-box.anonimizzati {
  background-color: #FFD862; /* Yellow */
}

.color-box.sbloccati {
  background-color: #8A62DC; /* Purple */
}

.color-box.maivisualizzati {
  background-color: #FF61A6; /* Pink */
}

.color-box.eliminati {
  background-color: #DC3545; /* Red */
}

.campaign-title {
      white-space: pre-wrap;
}

</style>
