<template>
  <div class="container-list">
    <div class="page-container referrals">
      <div class="topHeader">
        <searchBar :search="search" @update:search="search = $event" />
        <div class="buttonsOnTop">
          <!--           <span v-if="windowWidth <= 720" style="margin-right: 15px; display: flex; flex: 1">
            <material-button
              v-if="windowWidth <= 720"
              :text="companiesSelected.length != companies.length? 'Seleziona tutto':'Deseleziona tutto'"
              :small="true"
              :disabled="getCompanyListSelected.length === 0 || getCompanyListSelected.length > 1"
              @click.stop="selectAllCompanies"
            />
          </span> -->
          <!-- <button style="margin-right: 15px" @click.prevent.stop="newForm" :disabled="newExist">Aggiungi azienda</button>
					<button @click.stop="openRechargeModal" :disabled="getCompanyListSelected.length === 0">Ricarica Credito</button> -->

          <!-- <material-button text="Aggiungi azienda" @click.prevent.stop="newForm" :disabled="!!newExist" style="margin-right: 1rem" /> -->
          <material-button
            v-if="permissions.canUse('COMPANY_MONEY_ADD')"
            text="Accredita somma"
            :small="true"
            :disabled="getCompanyListSelected.length === 0 || getCompanyListSelected.length > 1"
            style="margin:0 5px"
            @click.stop="openRechargeModal('transfer')"
          />

          <material-button v-if="permissions.canUse('COMPANY_VAUCHER_ADD')" text="Gift voucher" :small="true" :disabled="getCompanyListSelected.length === 0" @click.stop="openRechargeModal('gift')" />
        </div>
      </div>

      <div class="table" :class="pages > 1 ? 'table-pagination' : ''">
        <table cellspacing="0">
          <thead>
            <th class="th50">
              <input id="mainCheckbox" type="checkbox" :checked="companiesSelected.length == companies.length" @click="selectAllCompanies()">
            </th>
            <th class="orderable min" @click="changeOrder('Azienda')">
              <div class="text-left">
                <span>Azienda</span>
                <font-awesome-icon v-if="filters.order.field == 'Azienda'" :icon="filters.order.asc ? 'arrow-down-a-z' : 'arrow-down-z-a'" />
              </div>
            </th>
            <th class="orderable text-center th120" @click="changeOrder('Coid')">
              <div>
                <span>Coid</span>
                <font-awesome-icon v-if="filters.order.field == 'Coid'" :icon="filters.order.asc ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
              </div>
            </th>
            <th v-if="false" class="orderable text-center th120" @click="changeOrder('Coid')">
              <div>
                <span>Ultima Modifica</span>
                <font-awesome-icon v-if="filters.order.field == 'LastUpdate'" :icon="filters.order.asc ? 'arrow-down-a-z' : 'arrow-down-z-a'" />
              </div>
            </th>
            <th class="orderable text-center th120" @click="changeOrder('source')">
              <div>
                <span>Sorgente</span>
                <font-awesome-icon v-if="filters.order.field == 'source'" :icon="filters.order.asc ? 'arrow-down-a-z' : 'arrow-down-z-a'" />
              </div>
            </th>
            <th class="orderable text-center th150" @click="changeOrder('current_contract')">
              <div>
                <span>Contratto</span>
                <font-awesome-icon v-if="filters.order.field == 'current_contract'" :icon="filters.order.asc ? 'arrow-down-a-z' : 'arrow-down-z-a'" />
              </div>
            </th>
            <th v-if="windowWidth >= 1100" class="orderable text-center th120" @click="changeOrder('Credito')">
              <div>
                <span>Credito</span>
                <font-awesome-icon v-if="filters.order.field == 'Credito'" :icon="filters.order.asc ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
              </div>
            </th>
            <th v-if="windowWidth >= 1100" class="orderable text-center th150" @click="changeOrder('Campagne')">
              <div>
                <span>Campagne</span>
                <font-awesome-icon v-if="filters.order.field == 'Campagne'" :icon="filters.order.asc ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
              </div>
            </th>
            <th class="th200">
              <div class="legend-container">
                <div class="legend-text" @click="changeOrder('Correnti')">
                  <div class="littlepoint Correnti" />
                  <div>Correnti</div>
                  <font-awesome-icon v-if="filters.order.field == 'Correnti'" :icon="filters.order.asc ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
                </div>
                <div class="legend-text" @click="changeOrder('Scadute')">
                  <div class="littlepoint Scadute" />
                  <div>Scadute</div>
                  <font-awesome-icon v-if="filters.order.field == 'Scadute'" :icon="filters.order.asc ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
                </div>
                <div class="legend-text" @click="changeOrder('Archiviate')">
                  <div class="littlepoint Archiviate" />
                  <div>Archiviate</div>
                  <font-awesome-icon v-if="filters.order.field == 'Archiviate'" :icon="filters.order.asc ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
                </div>
                <div class="legend-text" @click="changeOrder('Cancellate')">
                  <div class="littlepoint Cancellate" />
                  <div>Cancellate</div>
                  <font-awesome-icon v-if="filters.order.field == 'Cancellate'" :icon="filters.order.asc ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
                </div>
              </div>
            </th>
            <th class="th50">
              <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path fill="transparent" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
              </svg>
            </th>
          </thead>
          <tbody class="table-body">
            <slot v-for="(company, index) in getPaginateCompanies" :id="company.coid + 'panel' + index" :key="company.co_name + company.coid + index">
              <tr class="cursor-pointer" @click="toggleMasterDetail(company.coid)">
                <td class="text-center">
                  <input
                    :id="'collapsible' + index"
                    class="toggle"
                    type="checkbox"
                    :checked="companiesSelected.indexOf(company.coid) !== -1"
                    @change="select(company.coid)"
                    @click.stop=""
                  >
                </td>
                <td style="white-space: pre-wrap;">
                  <div>{{ company.co_name }}</div>
                </td>
                <td class="text-center">
                  <span class="badge coid">{{ company.coid === 'newCompany' ? null : company.coid }}</span>
                </td>
                <td v-if="false">
                  {{ company.lastUpdate ? company.lastUpdate : 0 }}
                </td>
                <td class="text-center">
                  {{ company.ad_source === 'recruiters' ? 'Recruiters' : 'EasyPost' }}
                </td>
                <td class="text-center">
                  <span class="badge coid" style="min-height:25px;min-width:25px;height:25px;width:25px; max-height:25px;max-width:25px" :style="company.ad_source == 'easy_post' ? 'background-color:#42b4f5' : company.contract_expired ? 'background-color:#f5425d' : 'background-color:#4ad966'" />
                </td>
                <td v-if="windowWidth >= 1100" class="text-center">
                  <div>
                    <div :id="'credit_count' + company.coid">
                      {{ company.credit_count }} €
                    </div>
                  </div>
                </td>
                <td v-if="windowWidth >= 1100" class="flex-1 text-center">
                  <donut
                    :coid="company.coid"
                    :tot="company.tot_campagne"
                    :size="50"
                    :sectors="[{
                      value: Number(company.campagne_correnti) ? Number(company.campagne_correnti) : 0,
                      label: 'Correnti',
                    }, {
                      value: Number(company.campagne_scadute) ? Number(company.campagne_scadute) : 0,
                      label: 'Scadute',
                    }, {
                      value: Number(company.campagne_archiviate) ? Number(company.campagne_archiviate) : 0,
                      label: 'Archiviate',
                    }, {
                      value: Number(company.campagne_cancellate) ? Number(company.campagne_cancellate) : 0,
                      label: 'Cancellate',
                    }]"
                  />
                  <span v-if="false">
                    <span v-if="typeof company.tot_campagne === 'number' && campaignsCount.indexOf(company.coid) === -1" class="badge">{{ company.tot_campagne }}</span>
                    <div v-if="typeof company.campagne_correnti === 'number' && campaignsCount.indexOf(company.coid) != -1" style="display: flex; justify-content: center">
                      <span class="badge current">{{ company.campagne_correnti }}</span>
                      <span class="badge archivied">{{ company.campagne_archiviate }}</span>
                    </div>
                  </span>
                </td>
                <td class="text-center">
                  <material-button v-if="company.tot_campagne > 0" :small="true" text="vedi campagne" type="info" @click.stop="openCampaign(company.coid)" />
                </td>
                <td>
                  <font-awesome-icon :icon="collapsiblePanelOpened == company.coid ? 'angle-down' : 'angle-right'" class="fa-xl" />
                </td>
              </tr>
              <tr v-if="collapsiblePanelOpened == company.coid" class="collapsible-content">
                <td :colspan="windowWidth >= 1100 ? 9 : 6" class="content-inner" style="padding: 0 !important">
                  <companyManagement v-if="company.coid === -1" @updateCompanies="updateCompanies" />
                  <SectionForm :id="checked" :key="'standForm' + company.coid" :company="companySelected" @campaignSaved="campaignSaved($event)" />
                </td>
              </tr>
            </slot>
          </tbody>
        </table>
      </div>

      <div v-show="getPaginateCompanies.length === 0 && loaded" class="no-result">
        Nessuna azienda da visualizzare
      </div>

      <div v-if="pages > 1" class="pagination">
        <div>
          <div>Visualizzati {{ itemsPerPage }} di {{ companies.length }} ({{ total_active_companies_count }} con almeno una campagna attiva)</div>
          <div>{{ total_active_recruiter_companies_count }} create da Recruiters e {{ total_active_easypost_companies_count }} create da easypost</div>
          
          <div style="display: flex; gap:1rem;">
            <div>Contratti easypost: {{ total_sky_blue_contracts_count }}</div>
            <div>Contratti attivi: {{ total_green_contracts_count }}</div>
            <div>Contratti inattivi: {{ total_red_contracts_count }}</div>
          </div>
        </div>
        <v-pagination v-model="page" :pages="pages" :range-size="1" active-color="#DCEDFF" />
      </div>
    </div>

    <modalRecharge
      v-if="showModalRecharge"
      :modal-active="showModalRecharge"
      :modal-type="modalType === 'gift' ? true : false"
      :selected="getCompanyListSelected"
      :all-opt="companies"
      @close="updateCvc"
      @update:selected="updateFromModal($event)"
    />
  </div>
</template>

<script>
import donut from './donut.vue'
import { sidebarStore } from '@/stores/sidebar'
import { navbarStore } from '@/stores/navbar'
import { spinnerStore } from '@/stores/spinner'
import { api, sbapibackoffice } from '@/api'
import { onBeforeMount, onMounted, computed, reactive, toRefs, inject } from 'vue'
import SectionForm from '../components/companiesList/companies/SectionsForm.vue'
import companyManagement from '../components/companiesList/companies/CompanyManagement.vue'
import modalRecharge from '../components/modalRecharge.vue'
import router from '../router'
import searchBar from '../components/searchBarList.vue'
import VPagination from '@hennge/vue3-pagination'
import '@hennge/vue3-pagination/dist/vue3-pagination.css'
import { permissionsStore } from '@/stores/permissions'
/* eslint-disable */
export default {
	components: {
		SectionForm,
		companyManagement,
		modalRecharge,
		searchBar,
		VPagination,
		donut
	},
	setup() {
		const sectors = [{
			value: 43,
			label: 'Correnti',
			color: '#1eff1e'
		}, {
			value: 22,
			label: 'Scadute',
			color: '#FFFF00'
		}, {
			value: 18,
			label: 'Archiviate',
			color: '#ffa407'
		}, {
			value: 32,
			label: 'Cancellate',
			color: '#dc3545'
		}]
		const permissions = permissionsStore()
		const state = reactive({
			modalType: 'gift',
			loaded: false,
			scrollDiv: null,
			search: '',
			filter: 'asc',
			companiesFiltred: [],
			newFormModal: false,
			showModalRecharge: false,
			companiesSelected: [],
			checked: null,
			campaignsCount: [],
			companies: [],
			page: 1,
			itemsPerPage: 15,
			windowWidth: window.innerWidth,
			collapsiblePanelOpened: null,

			filters: {
				order: {
					field: 'Azienda',
					asc: true,
				},
			},

			total_active_companies_count:0,
 			total_active_recruiter_companies_count:0,
 			total_active_easypost_companies_count:0,
      total_green_contracts_count: 0,
      total_red_contracts_count: 0,
      total_sky_blue_contracts_count: 0
		})

		const spinner = spinnerStore()
		const toast = inject('$toast')
		const navbar = navbarStore()
		const sidebar = sidebarStore()

		onBeforeMount(() => {
			spinner.show()
			sbapibackoffice
				.get('/companies?adSource=recruiters')
				.then((companies) => {
					let coids = []
					companies.data.forEach((company) => coids.push(company.coid))
					Object.assign(state.companies, companies.data)
				})
				.catch((err) => {
					toast.error('Non è stato possibile ottenere la lista delle aziende: ' + err)
					spinner.hide()
				})
		})

		onMounted(() => {
			navbar.title = 'Lista Aziende'
			//sidebar.close()
			window.addEventListener('resize', () => {
				sidebar.close()
				state.windowWidth = window.innerWidth
			})
			state.scrollDiv = document.getElementById('containerDiv')
			state.loaded = false

			getCounts()
		})

		const getCounts = () => {
      spinner.show()
      sbapibackoffice
        .get('/companies/active-companies-count')
        .then(({ data }) => {
					state.total_active_companies_count = data.total_active_companies_count,
 					state.total_active_recruiter_companies_count = data.total_active_recruiter_companies_count,
 					state.total_active_easypost_companies_count = data.total_active_easypost_companies_count,
          state.total_green_contracts_count = data.total_green_contracts_count,
          state.total_red_contracts_count = data.total_red_contracts_count,
          state.total_sky_blue_contracts_count = data.total_sky_blue_contracts_count
        })
        .finally(spinner.hide)
    }

		const getPaginateCompanies = computed(() => {
    const trimStart = (state.page - 1) * state.itemsPerPage;
    const trimEnd = trimStart + state.itemsPerPage;

    // Filtro per la ricerca
    const searchLowerCase = state.search.toLowerCase();
    const filteredCompanies = state.companies.filter((company) => {
        const name = company.co_name.toLowerCase();
        const coid = company.coid.toString();
        const credit_count = company.credit_count.toString();
        return name.includes(searchLowerCase) || coid.includes(searchLowerCase) || credit_count.includes(searchLowerCase);
    });

    // Funzione di ordinamento
    const sortFunctions = {
        'Azienda': (a, b) => a.co_name.localeCompare(b.co_name),
        'Coid': (a, b) => a.coid - b.coid,
        'Credito': (a, b) => a.credit_count - b.credit_count,
        'Campagne': (a, b) => a.tot_campagne - b.tot_campagne,
        'Correnti': (a, b) => a.campagne_correnti - b.campagne_correnti,
        'Scadute': (a, b) => a.campagne_scadute - b.campagne_scadute,
        'Archiviate': (a, b) => a.campagne_archiviate - b.campagne_archiviate,
        'Cancellate': (a, b) => a.campagne_cancellate - b.campagne_cancellate,
        'LastUpdate': (a, b) => (b.lastUpdate || 0) - (a.lastUpdate || 0),
        'current_contract': (a, b) => {
            if (a.contract_expired === b.contract_expired) return 0;
            return a.contract_expired ? 1 : -1;
			},
			'source': (a, b) => a.ad_source.localeCompare(b.ad_source)
    };

    // Ordina i risultati
    const sortField = state.filters.order.field;
    const sortFunction = sortFunctions[sortField];
    const sortedCompanies = filteredCompanies.slice().sort((a, b) => {
        const comparison = sortFunction(a, b);
        return state.filters.order.asc ? comparison : -comparison;
    });

    // Restituisci la pagina corrente
    return sortedCompanies.slice(trimStart, trimEnd);
});

		const updateCompanies = (newCompany) => {
			state.companies.forEach((company) => {
				if (company.coid === 'newCompany') {
					company.coid = newCompany.data.coid
					company.co_name = newCompany.data.co_name
					company.tot_campagne = 0
					company.campagne_correnti = 0
					company.campagne_archiviate = 0
					company.creationDate = new Date().toISOString().slice(0, 10)
				}
				state.checked = null
			})
		}

		const pages = computed(() => {
			return Math.ceil((state.companiesFiltred.length || state.companies.length )/ state.itemsPerPage)
		})

		const openRechargeModal = (type) => {
			if (getCompanyListSelected.value && getCompanyListSelected.value.length === 0) {
				return
			} else {
				state.checked = null
				state.modalType = type;
				state.showModalRecharge = true
			}
		}

		const openCampaign = (coid) => {
			let company = state.companies.find((c) => c.coid === coid)
			router.push({ path: '/campaignsList', query: { company: company.co_name, coid: coid, correnti: company.campagne_correnti, scadute: company.campagne_scadute, archiviate: company.campagne_archiviate } })
		}

		const cancelNewForm = () => {
			let form = state.companies.find((c) => c.coid === 'newCompany')
			let index = state.companies.indexOf(form)
			if (index != -1) {
				state.companies.splice(index, 1)
			}
			state.checked = null
			state.newFormModal = false
		}

		const newForm = () => {
			if (state.newFormModal) return

			state.newFormModal = true

			let obj = {
				name: 'New Form',
				credit_count: 0,
				coid: 'newCompany',
				co_name: '',
				creationDate: new Date().toISOString().slice(0, 10),
			}

			state.companies = [obj, ...state.companies]
			state.search = ''
			state.filter = 'casc'
			state.checked = 'newCompany'
			state.collapsiblePanelOpened = 'newCompany'
			//state.scrollDiv.scrollTop = 0
		}

		const close = () => {
			cancelNewForm()
			state.showModalRecharge = false
			state.newFormModal = false
		}

		const selectAllCompanies = () => {
			if (state.companiesSelected.length === state.companies.length) {
				state.companiesSelected = []
			} else {
				state.companiesSelected = [...new Set(state.companies.map((company) => company.coid))]
			}
		}

		const select = (coid) => {
			let index = state.companiesSelected.indexOf(coid)
			if (index === -1) state.companiesSelected.push(coid)
			else state.companiesSelected.splice(index, 1)
		}

		const updateFromModal = (newValues) => {
			state.companiesSelected = [...new Set(newValues.map((company) => company.coid))]
		}

		const getCompanyListSelected = computed(() => {
			return state.companies.filter((company) => state.companiesSelected.indexOf(company.coid) != -1)
		})

		const getCompanyList = computed(() => {
			return state.companies
		})

		const manageBadge = (coid, numb) => {
			return
			if (numb === 0) return

			let index = state.campaignsCount.indexOf(coid)
			if (index === -1) state.campaignsCount.push(coid)
			else state.campaignsCount.splice(index, 1)
		}

		const companySelected = reactive({})

		const updateCvc = (obj) => {
			if (obj.close) {
				state.showModalRecharge = false
			} else {
				if (obj) {
					if (obj.ArrayGift) {
						state.companies.forEach((company) => {
							obj.ArrayGift.forEach((transaction) => {
								if (company.coid === transaction.coid) {
									let val = Number(company.credit_count) + Number(transaction.transactionAmount)
									company.credit_count = val
								}
							})
						})
					} else {
						let company = state.companies.find(c => c.coid === obj.coid)
						if (company) {
							let val = Number(company.credit_count) + Number(obj.transactionAmount)
							company.credit_count = val
						}
					}
					state.showModalRecharge = false
					state.modalType === 'gift' ? toast.success('Gift voucher accreditato con successo!') : toast.success('Denaro accreditato con successo!')
				} else {
					state.showModalRecharge = false
					state.modalType === 'gift' ? toast.error('Non è stato possibile accreditare il gift voucher alle aziende ' + obj.err) : toast.error('Non è stato possibile accreditare il denaro ' + obj.err)
				}
			}
		}

		const getBadgeWidth = (credit_count) => {
			let number = Number(credit_count.toString().length)
			if (number < 3) {
				return
			} else {
				number = number * 13
				number = number < 35 ? 35 : number
				let height = number < 40 ? number : 35
				return 'width:' + number + 'px!important;height:' + height + 'px!important;border-radius:' + height + 'px'
			}
		}

		const newExist = computed(() => state.companies.find((company) => company.coid === 'newCompany'))

		const campaignSaved = (result) => {
			if (result.status && result.status === 200) {
				let table = document.getElementsByClassName('table')[0]
				table.scrollTo({ top: 0, behavior: 'smooth' });
				let campaignToUpdate = state.companies.find((c) => c.coid === result.coid)
				if (campaignToUpdate) {
					campaignToUpdate.co_name = result.co_name
					campaignToUpdate.lastUpdate = Date.now()
					state.filters.order.field = 'LastUpdate'
					state.page = 1;
					toast.success("L'anagrafica dell'azienda è stata aggiornata!")
				}
				//state.collapsiblePanelOpened = result.coid
				state.collapsiblePanelOpened = null
			} else {
				toast.error("Non è stato possibile aggiornare l'azienda: " + result)
			}
			state.checked = null
			Object.assign(companySelected, {})
			spinner.hide()
			//state.scrollDiv.scrollTop = 0
		}

		const toggleMasterDetail = (coid) => {
			// if the new company form is open, don't open other master details
			if (state.collapsiblePanelOpened === 'newCompany') return

			if (state.collapsiblePanelOpened === coid) state.collapsiblePanelOpened = null
			else {
				Object.assign(companySelected, {})
				spinner.show()
				api.post('/auth/get-company-data', { coid })
					.then((response) => {
						Object.assign(companySelected, response.data)
						state.collapsiblePanelOpened = coid
					})
					.catch((err) => {
						toast.error("Non è stato ottenere i dettagli dell'azienda: " + err)
					})
					.finally(spinner.hide)
			}
		}

		/**
		 * Set the order condition for the table
		 * @param {number} orderBy
		 */
		const changeOrder = (orderBy) => {
      if (state.filters.order.field == orderBy) {
        state.filters.order.asc = !state.filters.order.asc
      } else {
        state.filters.order.field = orderBy
        state.filters.order.asc = true
      }
    }

		return {
			...toRefs(state),
			sectors,
			getBadgeWidth,
			getPaginateCompanies,
			getCompanyList,
			manageBadge,
			companySelected,
			select,
			selectAllCompanies,
			newForm,
			close,
			getCompanyListSelected,
			openRechargeModal,
			openCampaign,
			pages,
			updateCompanies,
			updateFromModal,
			updateCvc,
			newExist,
			campaignSaved,
			cancelNewForm,
			toggleMasterDetail,
			changeOrder,
			permissions
		}
	},
}
</script>
<style lang="scss">
.seeCampaign {
	cursor: pointer !important;
	background: #42bade50 !important;
	display: block !important;
	font-size: 10px !important;
	min-width: 0 !important;
	border: 1px solid #024d86 !important;
}

.pagination {
	height: 120px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: white;

	ul.Pagination {
		justify-content: flex-end;

		li.PaginationControl>path {
			fill: rgba(255, 255, 255, 0.233);
		}

		.Control-active {
			fill: white;
		}

		.Page {
			width: 25px;
			height: 25px;
			color: white;
		}

		button.Page-active {
			background: var(--accented) !important;
			border: 1px solid var(--accented) !important;
			color: #003e73;
			font-size: 14px;
			font-weight: 600;
		}
	}
}

.container-list {
	table {
		//table-layout:fixed;
	}
	th.th50 {
		width:50px;
	}
	th.min {
		min-width:150px;
	}

	th.th120 {
		width:120px;
	}
	th.th150 {
		width:150px;
	}
	th.th200 {
		width:200px;
	}
}
</style>
<style lang="scss" scoped>
@use '../assets/scss/table';
@import '../assets/scss/lists.scss';

.table {
	max-height: calc(100vh - 220px);
	&.table-pagination {
		height: calc(100% - 150px);
	}
}

.buttonsOnTop {
	display: flex;
}

.expansion-panel-container {
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 5px;
	height: calc(100% - 160px);
	overflow: hidden;
}

input.select-company[type='checkbox'] {
	display: block !important;
}

.container-list {
	position: relative;
	display: flex;
	flex-direction: column;
	height: calc(100% - 50px);
}

div.page-container {
	height: 100%;
	width: calc(100% - 100px);
	font-family: 'Lato', sans-serif;
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	box-shadow: rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.12);
	margin: 30px 50px;
	font-size: 14px;

	.scroll {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		overflow-y: auto;
		height: calc(100% - 65px);
	}
}

// expansion panel section
.wrap-collabsible.selected {
	// order: -1;
	display: flex;
	flex-direction: column;
	opacity: 1;

	// margin-bottom: 25px;
	// margin-top: 25px;
	.lbl-toggle {
		background: radial-gradient(circle at 50% 100%, rgb(23 23 25 / 50%), rgb(8 59 100 / 61%));
		//background:radial-gradient(circle at 50% 100%, #0089db9c, #0770d063);
	}
}

.wrap-collabsible.unselected {
	opacity: 0.65;
}

input[type='checkbox'] {
	display: inline-block;
}

.header .lbl-toggle {
	height: 65px;
}

.lbl-toggle {
	border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.header .lbl-toggle,
#headerpanel {
	border-top: 0;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.lbl-toggle {
	display: block;
	padding: 1rem;
	color: white;
	background: transparent;
	position: relative;

	cursor: pointer;
	transition: all 0.25s ease-out;

	.panel-header-container {
		display: flex;
		align-items: center;
		justify-items: center;

		.flex-1 {
			display: flex;
			flex: 1;
			justify-content: center;
			align-content: center;
			align-items: center;
			text-align: center;

			span {
				white-space: nowrap;
			}

			&.co_name {
				justify-content: left;
				padding-left: 20px;
				text-align: left;
			}
		}

		.flex-0 {
			display: flex;
			flex: 0px;
			justify-content: center;
			text-align: center;
			align-content: center;
			align-items: center;
		}

		.flex-0.select {
			max-width: 120px;
			min-width: 105px;
		}
	}
}

.collapsible-content {
	max-height: 0px;
	overflow: hidden;
	transition: max-height 0.25s ease-in-out;
}

.toggle:checked+.lbl-toggle+.collapsible-content {
	max-height: 100vh;
}

.collapsible-content .content-inner {
	border-bottom: 1px solid #00dbc350;
	border-top: 1px solid #00dbc350;
	padding: 0.5rem 1rem;
}

.expansion-panel.selected .arrow svg {
	transform: rotate(90deg);
	transition: all 0.2s ease-in-out;
}

.arrow {
	flex: 0 !important;
	min-width: 40px;
	max-width: 40px;

	svg {
		transform: rotate(0deg);
		transition: all 0.2s ease-out;
	}
}

.topHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}

@media only screen and (max-width: 720px) {
	.topHeader {
		flex-direction: column;
	}

	.buttonsOnTop {
		margin-bottom: 20px;
		width: 100%;
	}

	.lbl-toggle {
		padding: 0rem 1rem;
	}

	.selected {
		margin: 0 !important;
	}

	div.expansion-panel.header.wrap-collabsible {
		display: none;
	}

	.panel-header-container {
		position: relative;
		flex-direction: column;

		div:not(.bordered):not(.bordered *) {
			display: block !important;
			width: 100%;
			max-width: 100% !important;
			position: relative;
			text-align: right !important;
			line-height: 38px;
			min-height: 38px;
			padding: 0 !important;
			word-spacing: 1px !important;

			svg {
				margin: 7px;
			}

			input.select-company {
				margin-top: 12.5px;
			}

			button {
				margin-left: auto;
				margin-right: auto;
			}
		}

		div:nth-of-type(4) {
			margin-top: 5px;
		}

		div:before {
			text-align: left;
			position: absolute;
			display: block;
			width: 50%;
			line-height: 38px;
			min-height: 38px;
		}

		div:nth-of-type(2):before {
			content: 'Nome Azienda';
		}

		div:nth-of-type(3):before {
			content: 'Coid';
		}

		div:nth-of-type(4):before {
			content: 'Credito';
		}

		div:nth-of-type():before {
			content: '';
		}
	}
}

@media (min-width: 601px) and (max-width: 1100px) {
	.co_name {
		display: -webkit-box !important;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-left: 5px !important;
	}

	.panel-header-container {
		div:not(.badge):not(.select) {
			min-width: 20%;
		}

		div:nth-of-type(5),
		div:nth-of-type(4) {
			word-spacing: 300px;
		}
	}
}

.legend-container {
	max-width: 160px;
	display: flex;
	flex-wrap: wrap;
	&.legend-container-row {
		flex-wrap:nowrap;
		position: absolute;
		width: 120px;
    left: -10px;
    justify-content: space-between;
		.legend-text {
			width: fit-content;
			flex: 0;
			flex-basis: unset;
			div:nth-child(2) {
				width: fit-content;
			}
		}
	}

	.legend-text {
		cursor: pointer;
		padding: 2px;
		display: flex;
		flex: 0 0 50%;
		align-items: center;
		flex-basis: 50%;
		font-size: 12px;

		div:nth-child(2) {
			width: 60px;
			text-align: left;
		}

		.littlepoint {
			width: 8px;
			height: 8px;
			border-radius: 1px;
			margin-right: 5px;

			&:nth-child(2),
			&:nth-child(4) {
				margin-left: 2px;
			}

			&.Correnti {
				background-color: #A9DC62;
			}

			&.Scadute {
				background-color: #FFD862;
			}

			&.Archiviate {
				background-color: #FF6188;
			}

			&.Cancellate {
				background-color: #dc3545;
			}
		}
	}
}

.table table th {
	padding: 0.5rem 1rem 0.5rem 0.5rem !important;

}
</style>
